import React from "react";
import { PriceCalculationCard } from "../../../CommonComponent";
import "../POS/pos.scss";
import { Col, Row } from "antd";
import {
  HoldButtonContainer,
  PaymentButtonContainer,
  ProductContainer,
  ProductPOSListContainer,
  TransactionButtonContainer,
  ViewOrderButtonContainer,
  ReferenceFormContainer,
  ReturnButtonContainer,
} from "../../../Component/POS";

const PosView = ({
  myPermissions,
  discountTotal,
  subTotalPrice,
  handlePayment,
  isBtnDisable,
  isStatus,
  productsTaxTotal,
  isHoldBtnDisable,
  // handleResetProductPart,
  searchValue,
  setSearchValue,
  paymentModal,
  setPaymentModal,
  isStatusHoldBtn,
  grandTotal,
  paymentSuccessDetails,
  holdDataLength,
  OnHoldModal,
  setOnHoldModal,
  systemSettingDetails,
  setLoyaltyPoint,
  setPound,
  pound,
  setLoyaltyMemberId,
  setRedeem,
  mixMatchDiscount,
  departments,
}) => {
  return (
    <div className="pos-page-main">
      <Row gutter={[18, 16]} className="pos-product-wrap">
        <Col
          span={24}
          xxl={15}
          xl={15}
          lg={24}
          md={24}
          sm={24}
          className="product-cards-wrap"
        >
          <div className="pos-product-list-main">
            <div className="product-cars-wrap">
              <div className="buttons-main">
                <div
                  className={`${holdDataLength ? "hold-blink" : ""} view-order-btn-main`}
                >
                  <ViewOrderButtonContainer />
                </div>
                {(myPermissions["D-007"]?.["P-004"] ||
                  myPermissions?.allAllowed) && <ReturnButtonContainer />}
                <TransactionButtonContainer />
              </div>
              <ProductContainer
                {...{ searchValue, setSearchValue, departments }}
              />
            </div>
          </div>
          {/* <Pagination
            current={paginationValue?.page}
            pageSize={paginationValue?.pageSize}
            total={total}
            showSizeChanger={true}
            onChange={handlePageChange}
            className="pagination"
          /> */}
        </Col>
        <Col
          span={24}
          xxl={9}
          xl={9}
          lg={24}
          md={24}
          sm={24}
          className="post-bill-main"
        >
          <ReferenceFormContainer
            {...{
              setLoyaltyPoint,
              setPound,
              setLoyaltyMemberId,
              setRedeem,
              systemSettingDetails,
            }}
          />
          <ProductPOSListContainer />
          <div className="payment-method-wrap">
            <PriceCalculationCard
              {...{
                subTotalPrice,
                systemSettingDetails,
                productsTaxTotal,
                discountTotal,
                pound,
                mixMatchDiscount,
              }}
            />
            <div className="grand-total-main">
              <h2 className="grand-total-title">Grand Total:</h2>
              <h1 className="grand-total-amount">
                {systemSettingDetails?.currency}{" "}
                {parseFloat(grandTotal).toFixed(2)}
              </h1>
            </div>
            <div className="payment-buttons-wrap">
              <HoldButtonContainer
                {...{
                  handlePayment,
                  isHoldBtnDisable,
                  isStatus: isStatusHoldBtn,
                  OnHoldModal,
                  setOnHoldModal,
                }}
              />
              <PaymentButtonContainer
                {...{
                  handlePayment,
                  isBtnDisable,
                  paymentModal,
                  setPaymentModal,
                  isStatus,
                  paymentSuccessDetails,
                  setRedeem,
                  setPound,
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PosView;
